/* eslint-disable @typescript-eslint/no-unused-vars*/
import { ReactThreeFiber } from "react-three-fiber";
/* eslint-enable @typescript-eslint/no-unused-vars*/

import * as THREE from "three";
import React, { Suspense, useRef, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { Canvas, useThree, useFrame } from "react-three-fiber";
import CameraControls from "camera-controls";
import TreatmentPlan from "./TreatmentPlanModel";
import "./index.css";
import { useViewerState, actions, UseViewerStateHookReturn, View } from "./viewer-state";
import { GUI } from "./GUI";
import { Object3D } from "three";
require('dotenv').config();
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Add missing cameraControls types
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
declare global {
  namespace JSX {
    interface IntrinsicElements {
      cameraControls: ReactThreeFiber.Object3DNode<CameraControls, typeof CameraControls>;
    }
  }
}
// Install camera controls
CameraControls.install({ THREE });

const { Color } = THREE;
const ANIMATION_SPEED = 500;

function App() {
  const viewerState: UseViewerStateHookReturn = useViewerState();

  return (
    <>
      {/* Canvas rendered by three js  */}
      <Canvas camera={{ position: [0, 0, 250], fov: 24, far: 500 }}>
        <Suspense fallback={<Loading />}>
          <Viewer {...viewerState} />
        </Suspense>
      </Canvas>
      {/* DOM */}
      <GUI {...viewerState} />
    </>
  );
}
// acts as a suspense fallback,
// this can't be a dom node since it is rendered by three js
// in the webGL canvas
const Loading = () => <group />;

const rotations = new Map<View, [number, number]>();

rotations.set("front", [0, Math.PI / 2]);
rotations.set("left", [-Math.PI / 2, Math.PI / 2]);
rotations.set("right", [Math.PI / 2, Math.PI / 2]);
rotations.set("bottom", [0, Math.PI]);
rotations.set("top", [Math.PI, -Math.PI]);

const Viewer = React.memo(({ state, dispatch, statusIs }: UseViewerStateHookReturn) => {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  const controls = useRef(new CameraControls(camera, domElement)).current;

  /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   | configure camera controls
   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  useEffect(() => {
    controls.maxDistance = 400;
    controls.minDistance = 100;
  }, [controls.maxDistance, controls.minDistance]);
  /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   | stops/start the turntable movement
   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  const onControlStart = useCallback(() => {
    if (statusIs("turnTable") || statusIs("animatingToView")) {
      dispatch(actions.setUserControlled());
    }
  }, [statusIs, dispatch]);

  useEffect(() => {
    controls.addEventListener("controlstart", onControlStart);
    return () => controls.removeAllEventListeners();
  }, [controls, onControlStart]);

  /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   | stop start animation
   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  useEffect(() => {
    let intervalID: any;
    if (state.playing) {
      intervalID = setInterval(() => {
        if (typeof state.totalStages === "number" && typeof state.currentStage === "number") {
          if (state.currentStage === state.totalStages - 1) {
            dispatch(actions.setCurrentStage(0));
          } else {
            dispatch(actions.setCurrentStage(state.currentStage + 1));
          }
        }
      }, ANIMATION_SPEED);
    } else {
      clearInterval(intervalID);
    }
    return () => clearInterval(intervalID);
  }, [dispatch, state.currentStage, state.playing, state.totalStages]);
  /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   | render loop
   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  useFrame((_threeState, delta) => {
    if (statusIs("turnTable")) {
      controls.rotate(-(delta / 40), 0, true);
    }
    if (statusIs("animatingToView")) {
      controls.normalizeRotations();
      const rot = rotations.get(state.view);
      if (Array.isArray(rot)) {
        controls.rotateTo(rot[0], rot[1], true);
      }
    }

    controls.update(delta);
  });

  return (
    <>
      <TreatmentPlan dispatch={dispatch} state={state} statusIs={statusIs} />
      <Lights />
    </>
  );
});

const lightColor = new Color("white");

function Lights() {
  const { camera } = useThree();
  const light = useRef<Object3D>();

  /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   | render loop
   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  useFrame(function flowCamera() {
    // Light follows camera
    if (typeof light.current != "undefined") {
      light.current.position.set(camera.position.x, camera.position.y, camera.position.z);
      light.current.lookAt(0, 0, 0);
    }
  });
  return (
    <>
      <ambientLight intensity={0.35} />
      <directionalLight name="camLight" position={[0, 0, 0]} intensity={0.55} color={lightColor} ref={light} />
    </>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
