import { useReducer } from "react";
export type View = "front" | "left" | "right" | "bottom" | "top";

type State = {
  // current status of the viewer
  status:
    | "initial" // the initial state
    | "loading" // loading initiated
    | "controlledByUser" // the user is rotating the model
    | "animatingToView" // the model is transitioning or has transition to a view
    | "turnTable" // the model is auto rotating
    | "error"; // something went wrong
  view: View; // the last selected view, will be the traget for animation
  totalStages: number | null; //
  currentStage: number | null;
  showLower: boolean; // show the upper teeth model
  showUpper: boolean; // show the lower teeth model
  playing: boolean; // true when the animation is playing
  error: string[]; // error messages
};

const initialState: State = {
  view: "front",
  status: "initial",
  totalStages: null,
  currentStage: null,
  playing: false,
  showLower: true,
  showUpper: true,
  error: []
};

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Actions / Events
 *~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
function setLoading() {
  return {
    type: "load"
  } as const;
}

function setUserControlled() {
  return {
    type: "userControlled"
  } as const;
}

function setReady(totalStages: number) {
  return {
    type: "ready",
    totalStages
  } as const;
}
function startTurntable() {
  return {
    type: "startTurntable"
  } as const;
}

function setCurrentStage(stage: number = 0, userInitiated: boolean = false) {
  return {
    type: "setStage",
    stage,
    userInitiated
  } as const;
}

function setView(view: View) {
  return {
    type: "setView",
    view
  } as const;
}

function togglePlay() {
  return {
    type: "togglePlay"
  } as const;
}

function error(message: string) {
  return {
    type: "error",
    message
  } as const;
}

export const actions = {
  setLoading,
  setReady,
  setUserControlled,
  startTurntable,
  setCurrentStage,
  setView,
  togglePlay,
  error
};

type Action = ReturnType<
  | typeof setLoading
  | typeof setReady
  | typeof setUserControlled
  | typeof startTurntable
  | typeof setCurrentStage
  | typeof setView
  | typeof togglePlay
  | typeof error
>;

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Reducer: takes state and action and return a new state
 *~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
const reducer = (state: State, action: Action): State => {
  if (process.env.NODE_ENV === "development") {
    console.log(`action: %c${action.type}`, "color: salmon");
  }
  switch (action.type) {
    case "load":
      return {
        ...state,
        status: "loading"
      };
    case "startTurntable": {
      return {
        ...state,
        status: "turnTable"
      };
    }
    case "userControlled": {
      return {
        ...state,
        status: "controlledByUser"
      };
    }
    case "setStage": {
      return {
        ...state,
        currentStage: action.stage
      };
    }
    case "setView": {
      const { view } = action;
      const showBoth = view === "front" || view === "left" || view === "right";
      return {
        ...state,
        status: "animatingToView",
        view,
        showUpper: showBoth || view === "bottom",
        showLower: showBoth || view === "top"
      };
    }
    case "ready":
      return {
        ...state,
        totalStages: action.totalStages,
        currentStage: 0,
        showLower: true,
        showUpper: true,
        status: "turnTable",
        error: []
      };
    case "togglePlay": {
      return {
        ...state,
        playing: !state.playing
      };
    }
    case "error": {
      return { ...state, status: "error", error: [...state.error, action.message] };
    }
    default:
      return state;
  }
};

export function useViewerState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const statusIs = (status: State["status"]) => status === state.status;
  if (process.env.NODE_ENV === "development") {
    console.log(`status: %c${state.status}`, "color: #690");
    console.log(`state: %O`, state);
  }

  return {
    state,
    dispatch,
    statusIs
  };
}

export type UseViewerStateHookReturn = ReturnType<typeof useViewerState>;
