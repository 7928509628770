import * as React from "react";
import { UseViewerStateHookReturn, actions, View } from "./viewer-state";

export function GUI({ state, dispatch, statusIs }: UseViewerStateHookReturn) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const startStepParam = params.get("startStep");
  const startStep = startStepParam && parseInt(startStepParam);

  const determineStep = () => {
    let currentStep;
    if (startStep) {
      currentStep = state.currentStage ? (state.currentStage + startStep - 1) : 0
    } else {
      currentStep = state.currentStage || 0
    }
    return currentStep
  }


  const totalSteps = startStep ? ((state.totalStages || 1) - 2 + startStep) : (state.totalStages || 1) - 1

  if (statusIs("error"))
    return (
      <div className="progressContainer">
        {state.error.map((message, idx) => (
          <div key={idx} className="error">
            {message}
          </div>
        ))}
      </div>
    );

  const isReady = !statusIs("loading") && !statusIs("initial") && typeof state.currentStage === "number";
  const getButtonProps = (view: View) => ({
    className: `ui_set_view ${state.view === view ? "active" : ""}`,
    onClick: () => dispatch(actions.setView(view))
  });
  if (!isReady) {
    return (
      <div className="progressContainer">
        <div className="loader">
          <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
        <label>Loading preview of treatment</label>
      </div>
    );
  } else {
    return (
      <div className="ui">
        <div className="ui_disclaimer"><p>Your treatment plan is simulated above, but your actual results may vary. When you approve your plan simulation, you are accepting it as representing your treatment goals.</p></div>
        <div className="ui_container">
          <div className="ui_row">
            <div onClick={() => dispatch(actions.togglePlay())} className="ui_play">
              <Play playing={state.playing}></Play>
            </div>
            <div className="ui_slider_container">
              <div className="ui_tick_container">
                {Array.from({ length: state.totalStages || 0 }).map((_, idx) => (
                  <div className="ui_tick" key={idx} />
                ))}
              </div>
              <div className="ui_slider_legend">
                <span>Start</span>
                <span>
                  Step {determineStep()} / {totalSteps}
                </span>
                <span>Retainer</span>
              </div>
              <input
                type="range"
                min={0}
                max={(state.totalStages as number) - 1}
                value={state.currentStage as number}
                onChange={e => {
                  dispatch(actions.setCurrentStage(Number(e.target.value), true));
                }}
                className="ui_slider"
              />
            </div>
          </div>
          <div className="ui_row">
            <button {...getButtonProps("front")}>Front</button>
            <button {...getButtonProps("bottom")}>Upper</button>
            <button {...getButtonProps("top")}>Lower</button>
          </div>
        </div>
      </div>
    );
  }
}

function Play({ playing }: { playing: boolean }) {
  return (
    <svg width="60px" height="60px" viewBox="0 0 36 36">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        {!playing ? (
          <g id="play">
            <path
              d="M18,36 C8.0588745,36 0,27.9411255 0,18 C0,8.0588745 8.0588745,0 18,0 C27.9411255,0 36,8.0588745 36,18 C36,27.9411255 27.9411255,36 18,36 Z"
              fill="#2B60C7"
              fillRule="nonzero"
            ></path>
            <polygon fill="#FFFFFF" points="25 18 14 25 14 11"></polygon>
          </g>
        ) : (
          <g id="pause">
            <path
              d="M17.9780755,0 C27.9317905,0 36,8.0682095 36,17.9780755 C36,27.9317905 27.9317905,36 17.9780755,36 C8.0682095,36 0,27.9317905 0,17.9780755 C0,8.0682095 8.0682095,0 17.9780755,0 Z"
              fill="#2B60C7"
            />
            <path
              d="M12.1023143,8.8136419 C13.0669915,8.8136419 14.0316687,8.8136419 14.952497,8.8136419 C15.4786845,8.8136419 15.8733252,9.20828258 15.8733252,9.69062119 C15.8733252,15.2155907 15.8733252,20.7405603 15.8733252,26.2655298 C15.8733252,26.7917174 15.4786845,27.1863581 14.952497,27.1863581 C14.0316687,27.1863581 13.0669915,27.1863581 12.1023143,27.1863581 C11.6199756,27.1863581 11.225335,26.7917174 11.225335,26.2655298 C11.225335,20.7405603 11.225335,15.2155907 11.225335,9.69062119 C11.225335,9.20828258 11.6199756,8.8136419 12.1023143,8.8136419 Z"
              fill="#FFFFFF"
            />
            <path
              d="M20.8769793,9 C21.8416565,9 22.8063337,9 23.727162,9 C24.2533496,9 24.6479903,9.39464068 24.6479903,9.87697929 C24.6479903,15.4019488 24.6479903,20.9269184 24.6479903,26.4518879 C24.6479903,26.9780755 24.2533496,27.3727162 23.727162,27.3727162 C22.8063337,27.3727162 21.8416565,27.3727162 20.8769793,27.3727162 C20.3946407,27.3727162 20,26.9780755 20,26.4518879 C20,20.9269184 20,15.4019488 20,9.87697929 C20,9.39464068 20.3946407,9 20.8769793,9 Z"
              fill="#FFFFFF"
            />
          </g>
        )}
      </g>
    </svg>
  );
}
